.artists-follow-recommandations {
  .bg {
    background-color: #000;
    height: 100vh;
    left: 0;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 3;
  }

  .box {
    padding: 0;
    border-radius: 20px;
    position: fixed;
    top: 130px;
    z-index: 4;
    overflow: hidden;
    background-color: #fff;

    @media(min-width: 350px) {
      left: calc((100vw - 450px)  / 2);
    }

    @media(min-width: 768px) {
      left: -125px;
      position: absolute;
      top: 45px;
    }

    .args {
      display: flex;
      flex-flow: column;
      font: normal normal 300 16px/30px Open Sans;
      max-width: 450px;
      padding: 30px;
      width: calc(100vw - 30px);

      @media(min-width: 768px) {
        width: 450px;
      }

      .close {
        position: absolute;
        right: -5px !important;
        top: -2px;
        
        img {
          height: 40px;
        }
      }
      
      .artp-action {
        text-align: center;
        margin: 20px 0;
        display: flex;
        justify-content: center;
        column-gap: 15px;
      }
    }

    .login {
      display: flex;
      flex-flow: row;
      column-gap: 5px;
      bottom: 5px;
      font-size: 12px;
      position: absolute;
      right: 10px;
      text-align: right;
      
      a { 
       text-transform: capitalize; 
      }
    }
  }
}

