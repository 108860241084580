.drawer-black-bg {
  .artp-drawer {

    &.report-reports-noaccessdrawer-container {
      display: flex;
      width: 600px;
      background-color: var(--common-gray);

      font-size: 15px;
      line-height: 23px;

      .close {
        padding: 15px;
        position: absolute;
        right: 0;
        top: 0;
      }

      .report-reports-noaccessdrawer-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 30px;
      }

      h3 {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
      }

      a.link {
        border: 1px solid #333;
        border-radius: 4px;
        padding: 15px 20px;
        margin-right: 25px;
        margin-top: 40px;
        transition: all linear 150ms;
        
        font: normal normal normal 15px/20px Open Sans;
        letter-spacing: 0px;
        

        &.report-reports-noaccessdrawer-registration{
          background: #233A84 0% 0% no-repeat padding-box;
          color: #FFFFFF;
          
          &:hover {
            background-color: #8598d7;
            border-color: var(--color-active-or-hover-border);
          }
        }

        &.report-reports-noaccessdrawer-connect{

          &:hover {
            background-color: var(--color-active-or-hover);
            border-color: var(--color-active-or-hover-border);
          }
        }
      }
    }
  }
}

