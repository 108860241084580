@use '../../../../../assets/stylesheets/constants';
@use '../../../../../assets/stylesheets/artp_colors';

$highlight-color: #ffd535;

.account-lots-lists-landing-page {
  font-family: 'Open Sans', serif;

  .highlight {
    display: table;
    margin: 0 auto;
    position: relative;

    .highlight-content {
      display: block;
      padding: 0 0.2em;
      position: relative;
      z-index: 11;
    }

    .highlight-color {
      background-color: $highlight-color;
      bottom: 3px;
      height: 0.5em;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 10;
    }
  }

  .header {
    background-color: #fcfcfc;
    min-height: 233px;
    position: relative;
    text-align: center;

    .title {
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 20px;
      padding-top: 26px;

      @media(max-width: 710px) {
        font-size: 26px;
      }
    }

    .presentation {
      font-size: 18px;
      line-height: 1.55;
      margin: auto;
      max-width: 954px;

      @media(max-width: 710px) {
        font-size: 16px;
        padding: 0 30px;
      }
    }
    
    .action {
      font-size: 16px;
      margin-bottom: 26px;
      margin-top: 20px;

      @media(max-width: 710px) {
        font-size: 14px;
      }
      
      &:hover {
        background-color: artp_colors.$primary;
        border-color: #2d6da3;
        color: artp_colors.$white;
      }
    }

    .center {
      position: relative;
    }

    .side {
      background-repeat: no-repeat;
      display: none;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;

      @media (min-width: 1200px) {
        display: block;
      }
    }

    .right {
      background-image: url(#{constants.$image-source}pdflists/header_right.jpg);
      background-position: right;
      right: 0;
    }

    .left {
      background-image: url(#{constants.$image-source}pdflists/header_left.jpg);
      background-position: left;
      left: 0;
    }
  }

  .content {
    .step {
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
      padding-bottom: 20px;

      @media (max-width: 768px) {
        font-size: 14px;
      }

      @media (max-width: 992px) {
        font-size: 16px;
      }

      .number {
        color: $highlight-color;
        font-family: Volkhov, 'Open Sans', serif;
        font-size: 2em;
        font-weight: normal;
        margin-right: 5px;
      }

      .image-container {
        margin: 10px auto 0;
        max-width: max(100%, 1140px);
        position: relative;
        width: fit-content;

        .border {
          border: 2px solid $highlight-color;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
        }
      }

      .image {
        max-width: 100%;
      }
    }
  }
}
