.search-common-sort {
  flex-direction: column;
  width: 320px;

  .freshness-title {
    color: #444;
    font-size: 1.2em;
  }

  .radios-list {
    display: flex;
    flex-flow: column;
    padding: 5px 15px;
    width: 100%;

    .option {
      color: #666;
      flex: 1;
      font-weight: unset !important;
    }
  }
}
