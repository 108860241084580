.marketplace-classified-actions {
  border: solid 1px #c9c9c9;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  margin-top: 30px;
  padding: 5px;
  text-align: center;

  .title {
    font: normal normal bold 15px/20px Open Sans;
    margin: 20px 0;
    text-transform: uppercase;
  }

  h2 {
    font: normal normal bold 16px/22px Open Sans;
    margin: 10px 0 25px;
    text-align: center;
  }

  .statute {
    color: #525252;
    font: normal normal bold 14px/22px Open Sans;

    .value {
      font: normal normal normal 14px/22px Open Sans;
    }
  }
}
