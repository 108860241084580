@use '../../../../../../assets/stylesheets/artp_colors';
@use '../../../../../../assets/stylesheets/constants' as stylesheets-constants;
@use '../../common/stylesheets/constants';

.subscriptions-free-services-registration {
  margin-bottom: 25px;
  margin-top: 25px;

  &.newcomer {
    margin-bottom: 0;
  }

  @media(min-width: 768px) {
    margin-bottom: 100px;
  }

  .container {
    .h2-newcomer {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 30px;
      text-align: center;

      @media (min-width: 992px) {
        font-size: 28px;
      }
    }

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin: 30px 0;
      text-align: center;

      @media (min-width: 992px) {
        font-size: 30px;
      }
    }

    .title {
      font: normal normal bold 18px/31px Open Sans;

      @media(min-width: 992px) {
        margin-top: 25px;
      }
    }

    .block {
      background-color: #fff;
      display: flex;
      flex-flow: column;
      overflow: hidden;

      @media(min-width: 992px) {
        flex-flow: row;
      }

      button {
        margin-top: 45px;
        
        @include constants.subscription-button;
      }

      .cell {
        background: #f4f3ef 0 0 no-repeat padding-box;
        border: solid 1px #f4f3ef;
        flex: 1;

        &.cell-arg-2 {
          padding-bottom: 15px;
        }

        &.cell-arg-1 {
          padding-top: 15px;
        }

        @media(min-width: 992px) {
          padding: 30px 20px;

          &.cell-arg-2 {
            margin-bottom: 0;
          }

          &.cell-arg-1 {
            margin-top: 0;
            padding-top: 30px;
          }
        }


        .title {
          font: normal normal bold 18px/34px Open Sans;
        }

        ul {
          margin-left: 40px;

          li {
            list-style-type: none;
            padding: 0;
            position: relative;

            p {
              font: normal normal normal 14px/24px Open Sans;
              margin-bottom: 20px;
              margin-top: 10px;
            }

            &::before {
              background-image: url('#{stylesheets-constants.$image-source}icon-check-free.svg');
              background-repeat: no-repeat;
              content: '';
              height: 18px;
              left: -30px;
              position: absolute;
              top: 10px;
              width: 20px;
            }
          }
        }

        &.cell-arg-0 {
          background-color: #fff;
          border: 0;
          display: flex;
          flex: none;
          flex-flow: row;
          justify-content: center;
          padding: 15px;
          text-align: center;
          width: 100%;

          @media(min-width: 992px) {
            flex-flow: column;
            padding: 0;
            width: 300px;
          }

          .block-2 {
            flex: 1;

            button.primary {
              background-color: artp_colors.$primary-bg;
              border: solid 1px artp_colors.$primary-border;
              color: #fff;
              transition: all linear 0.05s;

              &:hover {
                background-color: artp_colors.$primary-hover;
                border: solid 1px artp_colors.$primary-border-hover;
              }
            }
          }

          .block-1 {
            .icon {
              margin: 10px 0;
            }

            .title {
              font: normal normal bold 18px/34px Open Sans;

              @media(min-width: 992px) {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }


  &.no-data {
    background: #fff;
    padding: 10px 0 60px;

    .container {
      background-color: #f8f8f8;
    }
  }
}
