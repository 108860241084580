@use '../../../../../assets/stylesheets/artp_colors';

.search-bar-selection {
  margin: 5px 0;
  padding-left: 10px;

  .selection-group {
    display: flex;
    flex-flow: row;
  }
}
