@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  66% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.payments-ok {
  .container {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 16px;

    .email-validation {
      p {
        margin-bottom: 30px;
      }

      .email-status-link {
        margin-top: 30px;
      }
    }

    .block {
      box-shadow: 0 3px 6px #00000029;
      opacity: 1;
      width: 630px;
      max-width: 95%;
      padding: 30px 50px;
      margin-top: 60px;

      h1 {
        font-weight: 600;
        font-size: 26px;
        text-align: center;
      }

      .content {
        text-align: center;
        margin-top: 30px;

        i {
          font-size: 50px;
          padding: 30px 35px;
          border: dashed 1px #4d09a5;
          border-radius: 137px;
          color: #4d09a5;

          &.fa-spinner {
            border-color: transparent;
          }

          &.fa-exclamation-triangle {
            border-color: #ff0000;
            color: #ff0000;

            &:before {
              content: '⚠';
            }
          }

          &.fa-check {
            &:before {
              content: '✓';
            }
          }

          &.fa-hourglass-o {
            &.spin-anim {
              animation: 3s ease-in-out 1s infinite rotate;
            }
          }

          &.fa-info-circle {
            &:before {
              content: '🛈';
            }
          }
        }

        p {
          font-size: 16px;
          margin-top: 30px;
        }
      }
    }

    .infos {
      width: 630px;
      max-width: 95%;
      margin-top: 30px;
      text-align: center;

      .back {
        margin-top: 60px;
      }
    }
  }
}
