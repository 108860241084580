@use "sass:color";

.lots-tile-links {
  margin-bottom: 10px;
  position: relative;
  text-align: center;

  a {
    color: #333;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      color: color.adjust(#333, $lightness: -30%, $space: hsl);
      text-decoration: underline;
    }
  }
}
