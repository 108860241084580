.filters-mobile {
  align-items: center;
  display: flex;
  width: 100%;

  #filter {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    height: 34px;
    justify-self: end;
    margin-left: 20px;
    margin-right: 10px;
    min-width: inherit;
    padding: 5px 10px;
    position: relative;
    text-align: left;
    vertical-align: center;
  }

  .myartprice-addtolist-button {
    justify-content: space-between;
    margin-left: 20px;
    width: 100%;
  }

  .common-dropdown {
    border-style: none !important;
  }

  .common-dropdown .title button {
    color: #333 !important;
  }

  .title {
    button {
      border: solid 1px #ccc;
      border-radius: 5px;
      padding: 7px 10px 5px;
    }
  }
}
