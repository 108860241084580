.classified-description {
  margin-top: 0;

  .artist {
    font: normal normal bold 19px/26px Open Sans;
    margin-bottom: 15px;

    .button-follow {
      .btn {
        font-size: 12px;
      }
    }

    .artist-dates {
      font: normal normal bold 14px/19px Open Sans;
    }
  }

  h1.title {
    font: italic normal normal 18px/25px Open Sans;
    margin: 0;
    margin-bottom: 15px;

    .dates {
      font: normal normal normal 14px/25px Open Sans;
      margin-left: 5px;
    }
  }

  .idclassified {
    font: normal normal normal 16px/22px Open Sans;
    margin-bottom: 40px;
  }
}
