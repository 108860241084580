@use '../../../../assets/stylesheets/artp_colors';


.artist-index-summary {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  margin: 15px 0 30px;
  min-height: 440px;

  h2.digest {
    line-height: 35px;
    margin-bottom: 0;
    padding: 0 30px;
    text-align: center;
  }

  .common-Spinner {
    align-self: center;
  }

  .blocks {
    display: flex;
    flex-flow: column;
    padding-bottom: 30px;

    .block {
      align-items: center;
      column-gap: 50px;
      cursor: pointer;
      display: flex;
      height: 55px;
      margin: 0 40px;

      &:hover {
        .block-label {
          color: artp_colors.$link-blue-hover;
          text-decoration: underline;
        }
      }

      &:nth-child(odd) {
        background: #f5f5f5 0 0 no-repeat padding-box;
      }

      .block-label {
        color: artp_colors.$link-blue;
        flex: 1;
        font: normal normal bold 16px/22px Open Sans;
        padding-left: 45px;
      }

      .block-data {
        align-items: center;
        display: flex;
        flex: 1;
        font: normal normal normal 16px/22px Open Sans;
        height: 100%;

        img {
          height: 22px;
          margin-left: 15px;
          width: 22px;
        }
      }

      @media(max-width: 767px) {
        flex-flow: column;
        height: auto;
        margin: 0 15px;
        padding: 15px;

        .block-data {
          padding-right: 5px;
        }

        .block-label {
          margin-bottom: 5px;
          padding-left: 0;
          padding-right: 5px;
        }
      }
    }

    @media(min-width: 1200px) {
      margin: 0 175px;
    }
  }

  h1 {
    font-size: 2.2em;
    margin-top: 10px;
  }

  .strong {
    font-size: 1.1em;
    font-weight: bold;
  }
}
