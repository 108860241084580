@use '../../../../assets/stylesheets/constants';

.faq-desktop {
  column-gap: 30px;
  display: flex;
  margin-top: 30px;
  width: 100%;

  .back-button {
    position: absolute;
    top: 35px;
  }

  .common-Spinner {
    margin-top: 60px;
  }

  .left {
    font: normal normal bold 16px/22px Open Sans;
    width: 240px;

    ul {
      padding: 0;
      position: sticky;
      top: 132px;
      width: 100%;

      li {
        background-color: #f6f6f6;
        color: #404040;
        cursor: pointer;
        font: normal normal bold 16px/22px Open Sans;
        list-style-type: none;
        padding: 25px;
        text-align: center;
        transition: background-color 0.25s linear;
        width: 100%;

        &.active {
          background-color: #337ab7;
          color: #fff;
        }
      }
    }
  }

  .right {
    flex: 1;
    font: normal normal normal 16px/22px Open Sans;
    padding-top: 32px;

    ul {
      padding: 0;

      li {
        border-top: solid 1px #ccc;
        cursor: pointer;
        list-style-type: none;
        padding: 25px;
        transition: background-color 0.25s linear;

        img {
          max-width: 100%;
        }

        .faq-title {
          margin: 0;
        }

        .faq-item {
          margin: 0;
          max-height: 0;
          overflow: hidden;
          padding-left: 15px;

          li {
            border-top: 0;
            cursor: inherit;
            list-style-type: inherit;
            padding: inherit;
            transition: background-color 0.25s linear;
          }
        }

        &.active {
          background-color: #f6f6f6;

          .faq-title {
            font-size: 20px;
            font-weight: bold;
          }

          .faq-item {
            margin-top: 15px;
            max-height: 10000px;
          }
        }
      }
    }
  }
}

