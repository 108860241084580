@use '../../../../../../assets/stylesheets/artp_colors';

.search-search-drawer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 425px;

  @media(min-width: 768px) {
    width: 580px;
  }

  .header {
    background-color: #333;
    color: rgb(255, 255, 255);
    display: flex;
    flex-flow: row;
    font-size: 26px;
    font-weight: 700;
    height: 30px;
    margin-bottom: 15px;
    min-height: 50px;
    padding: 5px 15px;
    width: 100%;

    span {
      flex: 1;
    }

    .fa {
      cursor: pointer;
    }
  }

  .body {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;

    .search-item {
      margin: 5px;

      label {
        margin-bottom: 0;
      }
    }

    .info {
      color: #999;
      font-size: 12px;
      font-style: italic;
      padding-left: calc(33.33% + 30px);
      padding-right: 10px;
      white-space: normal;
    }

    .form-control {
      font-size: 12px;
      min-height: 30px;
    }

    .react-select__control {
      min-height: 34px;
    }

    .react-select__placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    @media (max-width: 767px) {
      font-size: 16px;

      .form-control {
        min-height: 34px;
      }

      .react-select__control {
        min-height: 34px;
      }

      .react-select__placeholder {
        font-size: 16px;
      }
    }
  }

  .footer {
    .info {
      background-color: artp_colors.$gray-lighter;
      font-size: 16px;
      margin-bottom: 0;
      min-height: 42px;
      padding: 10px 15px;
      text-align: center;
    }

    .search-drawer-submit {
      background-color: rgb(51, 122, 183);
      border-color: initial;
      border-image: initial;
      border-style: initial;
      border-width: 0;
      color: rgb(255, 255, 255);
      cursor: pointer;
      font-size: 20px;
      font-weight: 400;
      height: 60px;
      padding: 15px 0;
      text-align: center;
      transition: background-color 0.2s ease-out 0ms, width 0.2s cubic-bezier(0.25, 0.25, 0.75, 0.75) 0s;
      white-space: nowrap;
      width: 100%;
      z-index: 110;
    }
  }
}
