@use '../../../../../../assets/stylesheets/constants' as stylesheets-constants;
@use '../../common/stylesheets/constants';

.subscriptions-purchase-downgrade {
  &.lvl-#{stylesheets-constants.$theme-basics} {
    .artp-modal-header {
      background-color: constants.$color-basics !important;
    }
  }

  &.lvl-#{stylesheets-constants.$theme-premium} {
    .artp-modal-header {
      background-color: constants.$color-premium !important;
    }
  }

  &.lvl-#{stylesheets-constants.$theme-professional} {
    .artp-modal-header {
      background-color: constants.$color-professional !important;
    }
  }

  .artp-modal-header {
    color: #fff;
    font: normal normal bold 22px/31px Open Sans;

    &::before {
      background-image: url('#{stylesheets-constants.$image-source}icon-warning.svg');
      background-repeat: no-repeat;
      background-size: 60px 60px;
      content: '';
      height: 60px;
      left: 10px;
      position: absolute;
      width: 60px;
    }

    h2 {
      padding-left: 70px !important;
      font: normal normal bold 16px/31px Open Sans;
      margin: 20px 0;

      @media (min-width: 768px) {
        font-size: 22px;
      }
    }
  }

  .content {
    color: #433e49;

    @media (min-width: 768px) {
      padding: 0 30px;
    }

    p {
      font: normal normal bold 16px/31px Open Sans;
      margin: 15px 0;

      &.normal {
        font-weight: normal;
      }
    }

    ul {
      margin: 0;
      padding-left: 15px;

      @media (min-width: 768px) {
        padding-left: 60px;
      }

      li {
        font: normal normal normal 16px/26px Open Sans;
        list-style-type: none;
        margin-bottom: 5px;
        padding: 0 0 0 15px;

        &::before {
          background-repeat: no-repeat;
          content: '';
          display: inline-block;
          height: 10px;
          top: -5px;
          width: 25px;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 30px;

    @media(min-width: 425px) {
      flex-flow: row;
    }

    .subscriptions-list-theme-subscribe-button {
      padding: 0 5px;

      button {
        margin-bottom: 20px;
        margin-top: 0;
        padding: 0 5px;
        width: 100%;

        @include constants.subscription-button;
        
        @media(min-width: 768px) {
          padding: 0 15px;
        }

        &.close-action {
          @media (min-width: 768px) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
