.artp-footer {
  margin-top: 100px;
  color: #5e636e;
  width: 100%;
  font-family: 'Open Sans', 'Sans-Serif', serif;
  z-index: 1;
  position: relative;
  background-color: #fff;
  
  a {
    color: #5e636e;
  }

  .l-social {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-bottom: 15px;

    @media print {
      display: none;
    }

    div {
      font-size: 20px;
      margin: 0 10px;

      &.linkedin {
        img {
          height: 38px;
        }
      }

      &.twitter-x {
        img {
          height: 38px;
        }
      }
    }
  }

  .l1 {
    border-top: solid 1px #cac6ba;
    padding: 30px 0;
    display: grid;
    grid-template-columns: 1fr;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;

    @media print {
      display: none;
    }

    @media (min-width: 768px) {
      grid-template-columns: 33% 33% 33%;
    }

    .section {
      margin-bottom: 20px;
      display: flex;
      column-gap: 20px;
    }

    .l1-0,
    .l1-1,
    .l1-2 {
      padding: 0 10px 0 30px;
    }
  }


  .l-localisation {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 15px 30px 0;
    border-top: solid 1px #f1f1f1;
    color: #5e636e;
    font-size: 14px;
    text-align: center;

    @media (min-width: 992px) {
      flex-flow: row;

      .l-address {
        text-align: left;
      }

      .l-flags {
        text-align: right;
      }
    }
  }

  .l-ddc {
    padding: 15px 30px 0;
    font-size: 14px;
  }

  .l2 {
    padding: 15px 30px 40px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    text-align: center;
    row-gap: 15px;

    @media (min-width: 992px) {
      flex-flow: row;

      .l2-0 {
        text-align: left;
      }

      .l2-1 {
        text-align: right;
      }
    }
  }
}
