@use "sass:color";
@mixin btn-default($color) {
  background-color: $color;
  border-color: color.adjust($color, $lightness: -10%);
  color: #fff;

  &:disabled,
  &.disabled {
    opacity: 0.15;
    font-style: italic;
  }

  &:hover {
    background-color: color.adjust($color, $lightness: -10%);
    border-color: color.adjust($color, $lightness: -10%);
  }
}

.artp-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  padding: 10px 20px;
  user-select: none;
  border-radius: 5px;
  background-color: #fff;
  transition: all linear 0.2s;
  border: solid 1px #CAC6BA;
  align-content: center;
  color: #5E636E;
  font-size: 16px;
  
  &:hover {
    text-decoration: none;
    border: solid 1px #CAC6BA;
    background-color: #e6e6e6;
    color: #5E636E;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    filter: alpha(opacity = 65);
    opacity: 0.4;
    
    &:hover {
      background-color: inherit; 
    }
  }
  
  &.artp-btn-transparent {
    background-color: transparent;
    color: #fff;
    border-color: #fff;

    &:hover {
      color: #000;
      background-color: #fff;
      border-color: #fff;
    }
  }
  
  &.artp-btn-blue {
    $color: #233a84;
    @include btn-default($color);
  }
  
  
  &.artp-btn-purple {
    $color: #4D09A5;
    @include btn-default($color);
  }
}
