@use "sass:color";
@use '../common/stylesheets/constants';

@mixin override-button {
  & {
    border: 0;
    border-radius: 0;
    color: #999;
  }
}

.subscriptions-purchase-settings {
  display: flex;
  flex-flow: column;
  margin-top: 15px;

  @media(min-width: 768px) {
    align-items: normal;
    flex-flow: row;
    justify-content: space-between;
    margin-top: 40px;

    &.no-subscription {
      margin-top: 25px;
    }
  }

  .control-container {
    align-items: center;
    color: #000;
    display: flex;
    flex-flow: column;
    margin-bottom: 15px;

    .button-group {
      border: 1px solid #999;
      border-radius: 4px;
      display: flex;
      overflow: hidden;

      button {
        padding: 0;
        
        @include constants.subscription-button;
        @include override-button;

        &.active {
          box-shadow: inset 1px 1px 10px color.adjust(constants.$main-blue, $lightness: -10%);
        }
      }

      &.view {
        button {
          min-width: 100px;
          padding: 0 10px;
        }
      }
    }

    &.view {
      align-items: center;
      position: relative;
      width: 100%;

      @media(min-width: 768px) {
        align-items: flex-start;
        flex: 1;

        .subtitle {
          position: absolute;
          top: -25px;
          white-space: nowrap;
        }
      }

      .info-users {
        color: constants.$main-price;
        font-size: 16px;
        font-weight: 600;
        margin-top: 5px;

        &.no-controls {
          bottom: auto;
          margin-top: 5px;
          position: relative;
          top: 0;
        }

        p {
          margin: 0;
          text-align: center;

          @media(min-width: 768px) {
            text-align: left;
          }

          &.refund {
            cursor: pointer;
          }

          &.subtitle {
            color: #000;
            font: normal normal normal 14px/15px Open Sans;
            margin-bottom: 5px;
          }
        }
      }
    }

    &.promocode {
      align-items: center;
      justify-content: right;
      position: relative;
      width: 100%;

      @media(min-width: 768px) {
        align-items: flex-start;
        flex-direction: row-reverse;
        width: 250px;
      }

      @media(min-width: 992px) {
        flex: 1;
      }

      .info-promocode {
        bottom: -28px;
        color: #f03;
        font-size: 16px;
        font-weight: bold;
        margin-top: 15px;
        text-align: center;

        @media(min-width: 768px) {
          margin-top: 0;
          position: absolute;
          right: 0;
          text-align: left;
          top: 46px;
        }

        .message {
          color: #f03;
          margin-top: -3px;
        }
      }

      .code {
        border: 1px solid constants.$main-blue-border;
        border-radius: 4px;
        overflow: hidden;

        input[type=text] {
          border: 0;
          font-size: 16px;
          outline-style: none;
          padding-left: 10px;
          width: 165px;

          @media(min-width: 992px) {
            width: 200px;
          }
        }

        button {
          @include constants.subscription-button;
          @include override-button;
        }
      }
    }

    &.users {
      flex: 1;
      position: relative;

      .info-users {
        font: normal normal normal 14px/15px Open Sans;
        margin-top: 5px;
        text-align: center;
        width: 100%;
      }

      .controls {
        .users {
          background-color: #fff;
          border: 1px solid constants.$main-blue-border;
          border-radius: 4px;
          display: flex;
          flex-flow: row;
          height: 37px;
          overflow: hidden;

          .title {
            color: #000;
            display: flex;
            flex-flow: row;
            font: normal normal bold 16px/31px open sans;
            padding: 2px 0 0 10px;

            .fa {
              margin-right: 6px;
              margin-top: 6px;
            }

            .placeholder {
              display: none;

              @media(min-width: 992px) {
                display: block;
              }
            }
          }

          .quantity {
            font: normal normal bold 16px/31px open sans;
            justify-content: center;
            padding-top: 2px;
            text-align: center;
            width: 25px;
          }

          .button-container {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: center;
            width: 50px;

            .button {
              background-color: #fff;
              border: solid 1px #c1c1c1;
              border-radius: 25px;
              font-weight: 600;
              height: 25px;
              padding: 2px 0 0 6px;
              transition: background-color linear 0.25s;
              width: 25px;
            }

            &.disabled {
              color: #999;
              cursor: wait;
            }

            &:hover {
              .button {
                background-color: color.adjust(#fff, $lightness: -20%);
                border-color: color.adjust(#c1c1c1, $lightness: -20%);
              }
            }
          }
        }
      }
    }
  }
}
