@use "sass:color";
@use '../../Cards/constants';

.subscriptions-list-warnings-update-or-renew {
  width: 90%;
  max-width: 900px;

  .content {
    h1 {
      font-size: 27px;
    }

    @media (min-width: 768px) {
      padding: 0 30px;
    }

    p {
      font-size: 18px;
      margin: 15px 0;
      line-height: 30px;

      &.normal {
        font-weight: normal;
      }
    }

    ul {
      margin: 0;
      padding-left: 15px;

      @media (min-width: 768px) {
        padding-left: 60px;
      }

      li {
        font: normal normal normal 16px/26px Open Sans;
        list-style-type: none;
        margin-bottom: 5px;
        padding: 0 0 0 15px;

        &::before {
          background-repeat: no-repeat;
          content: '';
          display: inline-block;
          height: 10px;
          top: -5px;
          width: 25px;
        }
      }
    }
  }

  .btn-actions {
    display: flex;
    flex-flow: column;
    justify-content: center !important;
    margin: 40px 0 30px;
    column-gap: 30px;
    
    @media(min-width: 425px) {
      flex-flow: row;
    }

    button {
      font-size: 16px;
      height: 50px !important;
      min-width: 250px;
      font-weight: 500;
    }
    
    .close-action {

      &.basics {
        color: #000;
        background-color: constants.$basic;
        border: solid 1px color.adjust(constants.$basic, $lightness: -10%);

        &:hover {
          background-color: color.adjust(constants.$basic, $lightness: -10%);
        }
      }

      &.basics.store {
        color: #000;
        background-color: constants.$basicsstore;
        border: solid 1px color.adjust(constants.$basicsstore, $lightness: -10%);

        &:hover {
          background-color: color.adjust(constants.$basicsstore, $lightness: -10%);
        }
      }

      &.premium {
        color: #fff;
        background-color: constants.$premium;
        border: solid 1px color.adjust(constants.$premium, $lightness: -10%);

        &:hover {
          background-color: color.adjust(constants.$premium, $lightness: -10%);
        }
      }

      &.professional {
        color: #000;
        background-color: constants.$professional;
        border: solid 1px color.adjust(constants.$professional, $lightness: -10%);

        &:hover {
          background-color: color.adjust(constants.$professional, $lightness: -10%);
        }

        &.store {
          color: #fff;
          background-color: constants.$marketplace-professional;
          border: solid 1px color.adjust(constants.$marketplace-professional, $lightness: -10%);

          &:hover {
            background-color: color.adjust(constants.$marketplace-professional, $lightness: -10%);
          }
        }
      }

      &.artprice.store {
        color: #000;
        background-color: constants.$store;
        border: solid 1px color.adjust(constants.$store, $lightness: -10%);

        &:hover {
          background-color: color.adjust(constants.$store, $lightness: -10%);
        }
      }
    }
  }
}
