.marketplace-classified-same-to-sell {
  margin: 30px 0;

  @media(min-width: 768px) {
    margin-top: 15px;
  }

  .label {
    color: #646464;
    font: normal normal normal 14px/18px 'Open Sans';
    padding: 0;
    white-space: normal;
  }

  .link {
    font: normal normal normal 14px/22px 'Open Sans';
    margin-top: 10px;
  }
}
