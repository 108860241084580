.account-verify-identity {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;

  h1 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .header {
    border-top: solid 1px #ccc;
    margin-bottom: 15px;

    h2 {
      font-size: 22px;
      margin-bottom: 15px;
    }
  }

  .submit-btn {
    border-top: solid 1px #ccc;
    margin-bottom: 15px;
    padding-top: 15px;
  }

  .col-form-label {
    text-align: right;

    &::after {
      content: '*';
    }

    &.optional {
      font-weight: normal;

      &::after {
        content: none;
      }
    }
  }

  .address-replacement {
    border-left: solid 5px #ccc;
    color: #777;
    font-size: 85%;
    height: 300px;
    padding-left: 5px;
    padding-top: 100px;
    position: absolute;
    right: 0;
    width: 33.3333333%;

    @media(max-width: 1200px) {
      padding-top: 20px;
      width: 25%;
    }

    @media(max-width: 992px) {
      display: none;
    }
  }

  .siret-or-tva {
    border-left: solid 5px #ccc;
    color: #777;
    font-size: 85%;
    height: 100px;
    padding-left: 5px;
    padding-top: 30px;
    position: absolute;
    right: 0;
    width: 33.3333333%;

    @media(max-width: 1200px) {
      padding-top: 20px;
      width: 25%;
    }

    @media(max-width: 992px) {
      display: none;
    }
  }

  .siret-or-tva-inline {
    @media(min-width: 993px) {
      display: none;
    }
  }


  .file-group {
    .col-form-label {
      text-align: justify;
    }
  }

  .react-select__control {
    height: 34px;
    width: 100%;

    &.react-select__control--is-disabled {
      background-color: #eee;
    }
  }

  .react-select__indicators {
    .react-select__indicator {
      padding: 1px;
    }
  }
}
