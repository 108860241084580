.filters {
  border-width: 0;
  font-family: 'Open Sans', sans-serif;
  position: relative;
  width: 100%;
  z-index: 44;

  .artp-input-group {
    align-items: center;
    display: flex;
    padding: 7px;

    .artp-input-label {
      align-self: center;
      color: #999;
      font-size: 14px;
      padding: 0 10px 0 0;
      text-align: right;
      width: 150px;
    }

    .search-bar-input {
      display: flex;
      flex-flow: row;

      .cell {
        padding-left: 3px;

        &.flex {
          flex: 1;
        }

        &.offset {
          margin-left: 35%;
        }

        &.first {
          padding-left: 0;
        }
      }
    }

    .max-height-38 {
      height: 38px;
    }
  }

  .filters-container {
    display: flex;
    justify-content: flex-start;

    .common-dropdown {
      background: none;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px 10px;
      text-align: left;
      text-decoration: none;
      transition: background ease-out 100ms 0ms;

      &:hover {
        background-color: #eee;
      }

      .title {
        font-size: 14px;

        button {
          color: #000;
          outline: none;
          padding: 0;

          i {
            vertical-align: middle;
          }
        }
      }
    }

    .myartprice-addtolist-button {
      justify-content: space-between;
      width: 100%;
    }
  }

}
