.myartprice-lots-vertical-bar-content {
  .shortcuts {
    margin-bottom: 15px;

    .header {
      cursor: pointer;
      font-size: 16px;
      letter-spacing: 1px;
      padding-left: 5px;

      .fa {
        color: #999;
        margin-left: 5px;

        &.fa-caret-down {
          transition: all 0.5s ease;
        }
      }
    }

    .header-settings {
      color: #888;
      font-size: 16px;
      letter-spacing: 1px;
      margin-bottom: 10px;
      padding-left: 5px;
    }

    .links {
      overflow: hidden;
      transition: max-height 0.5s ease;

      a {
        color: #000;
        text-decoration: none;
      }

      .shortcut {
        color: #000;
        cursor: pointer;
        padding: 2px 5px 2px 10px;
        text-decoration: none;
        transition: background-color 0.5s ease-in-out;

        &:hover {
          background-color: #c9c9c9;
          border-radius: 3px;
          z-index: 10;
        }

        &.active {
          font-weight: bold;
        }

        p {
          margin: 0;
        }

        .fa {
          margin-right: 5px;
          width: 15px;
        }
      }
    }

    &.collapsed {
      .header {
        .fa-caret-down {
          transform: rotate(-180deg);
        }
      }

      .links {
        max-height: 0 !important;
      }
    }
  }
}


