.myartprice-lots-vertical-bar {
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-right: 15px;
  max-width: 240px;
  padding: 0 5px 10px;
  position: relative;
  width: 30%;
}
