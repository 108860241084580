.marketplace-classified-actions-price {
  margin-bottom: 25px;
  position: relative;

  .classified-details-price-block {
    font: normal normal normal 14px/22px Open Sans;
    text-align: center;

    .classified-details-price-label {
      font: normal normal bold 14px/22px Open Sans;
      text-transform: uppercase;
    }
  }
}
