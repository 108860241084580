@use "sass:color";
.indexes-demo-mode {
  border: solid 1px #fff;
  border-radius: 6px;
  color: #fff;
  display: flex;
  flex-flow: row;
  font: normal normal normal 16px/25px Open Sans;
  padding: 15px;

  .texts {
    flex: 1;

    a {
      $color: color.adjust(#2d6da3, $lightness: -20%);
      color: #fff;
      text-decoration: underline;


      &.btn {
        background-color: #fff;
        border: 1px solid $color;
        color: $color;
        text-decoration: none;

        &:hover {
          background-color: color.adjust(#fff, $lightness: -20%);
          border-color: color.adjust($color, $lightness: -50%);
          color: $color;
        }
      }
    }
  }

  .icon {
    align-self: center;
    text-align: center;
    width: 100px;
  }
}
