@use '../../../../../../assets/stylesheets/constants' as stylesheets-constants;
@use '../../common/stylesheets/constants';

.subscriptions-purchase-list-theme-details {
  max-width: 95%;

  .artp-modal-header {
    color: #fff;
    font: normal normal bold 22px/31px Open Sans;
    text-align: center;

    h2 {
      margin: 20px 0;
      width: 100%;
    }
  }

  &.lvl-#{stylesheets-constants.$theme-basics} {
    .artp-modal-header {
      background-color: constants.$color-basics !important;
    }

    .artp-modal-content {
      .content {
        .module-container.active {
          color: constants.$color-basics !important;
        }
      }
    }
  }

  &.lvl-#{stylesheets-constants.$theme-premium} {
    .artp-modal-header {
      background-color: constants.$color-premium !important;
    }

    .artp-modal-content {
      .content {
        .module-container.active {
          .module-label {
            color: constants.$color-premium !important;
          }
        }
      }
    }
  }

  &.lvl-#{stylesheets-constants.$theme-professional} {
    .artp-modal-header {
      background-color: constants.$color-professional !important;
    }

    .artp-modal-content {
      .content {
        .module-container.active {
          .module-label {
            color: constants.$color-professional !important;
          }
        }
      }
    }
  }

  &.lvl-#{stylesheets-constants.$theme-basics-store} {
    .artp-modal-header {
      background-color: constants.$color-basics-store !important;
    }

    .artp-modal-content {
      .content {
        .module-container.active {
          .module-label {
            color: constants.$color-basics-store !important;
          }
        }
      }
    }
  }

  &.lvl-#{stylesheets-constants.$theme-store} {
    .artp-modal-header {
      background-color: constants.$color-store !important;
    }

    .artp-modal-content {
      .content {
        .module-container.active {
          .module-label {
            color: constants.$color-store !important;
          }
        }
      }
    }
  }
}

.artp-modal-content {
  padding: 0;

  .content {
    color: #433e49;

    .module-container {
      padding: 0;

      .module {
        border-top: solid 1px #7070704d;
        display: flex;
        flex-flow: column;
        padding: 15px 0;

        @media(min-width: 768px) {
          flex-flow: row;
        }

        &.no-border {
          border-top: 0;
        }

        div {
          &.module-label {
            align-items: center;
            color: constants.$second-gray;
            cursor: pointer;
            display: flex;
            font-weight: 600;

            .fa {
              margin-left: 5px;
            }

            @media(min-width: 768px) {
              cursor: default;
              width: 190px;

              .fa {
                display: none;
              }
            }
          }

          &.module-details {
            align-items: center;
            flex: 1;
            font-weight: normal;
            overflow: hidden;
            padding-left: 15px;
            transition: all linear 200ms;

            @media(min-width: 768px) {
              max-height: 500px !important;
              padding-top: 0 !important;
            }
          }
        }

        a {
          color: constants.$main-blue;
          font: normal normal bold 14px/31px Open Sans;
          text-decoration: underline;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    margin-top: 30px;
    padding-right: 0;

    button {
      margin-bottom: 0;
      margin-top: 0;
      
      @include constants.subscription-button;
    }
  }
}
