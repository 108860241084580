@use '../../../../assets/stylesheets/constants';

.account-login-or-subscribe {
  background-repeat: no-repeat;
  background-position: right;
  background-image: url("#{constants.$image-source}login.png");
  display: flex;
  min-height: 971px;
  font-family: 'Open Sans', serif;
  position: relative;

  .bg {
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.75;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }

  .content {
    z-index: 1;
    background-color: #fff;
    width: 610px;
    padding: 45px 40px;
    position: relative;

    .title {
      font-weight: 400;
      font-size: 14px;
      align-items: center;
      color: #5E636E;
    }

    h2 {
      font-weight: 300;
      font-size: 35px;
      line-height: 50px;
      letter-spacing: -0.02em;
      margin-top: 10px;
    }

    .header {
      margin-top: 50px;
      font-weight: 300;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      display: flex;
      flex-flow: row;

      div {
        flex: 50%;
        padding-bottom: 10px;
        border-bottom: solid 3px #D9D9D9;

        a {
          color: #000;

          &:hover {
            text-decoration: none;
          }
        }

        &:first-of-type {
          border-color: #233A84;
        }
      }
    }

    .form {
      div {
        position: relative;

        &.login-btn {
          margin-top: 30px;
          text-align: center;

          @media(min-width: 768px) {
            text-align: left;
          }
        }

        &.forgot {
          margin-top: 20px;

          a {
            font-weight: 300;
            font-size: 12px;
            line-height: 23px;
            color: #5E636E;
          }
        }

        &.cgv,
        &.help {
          font-weight: 300;
          font-size: 10px;
          line-height: 14px;
          border: 0;
          height: auto;
          color: #1D1D1F;
          margin-top: 35px;
        }

        &.error {
          margin-top: 15px;
          color: #F00;
          font-weight: 600;
        }

        button {
          padding: 15px 40px;
          margin-top: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #FFF;
          background: #233A84;
          border-radius: 10px;
          border: 0;
        }

        .fa-eye-slash,
        .fa-eye {
          position: absolute;
          right: 0;
          bottom: 5px;
          color: #999;
          cursor: pointer;
        }

        input {
          margin-top: 45px;
          border: 0;
          border-bottom: solid 1px #D9D9D9;
          width: 100%;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 23px;
          outline: none;
        }
      }
    }
  }

  @media (max-width: 767px) {
    background-image: unset;
    min-height: 600px;

    .bg {
      display: none;
    }

    .content {
      width: 100%;

      h2 {
        font-size: 24px;
        line-height: 32px;
      }

      .header {
        margin-top: 20px;
      }
    }
  }
}
