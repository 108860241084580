@use '../../../../../../assets/stylesheets/artp_colors';

.artp-search-input {
  background-color: inherit;
  display: flex;
  height: 100%;
  width: 100%;
  
  input {
    border: solid 1px #ccc;
    border-radius: 4px;
    flex: 1 1 auto;
    font-size: 16px;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    
    &::placeholder {
      color: #999;
      opacity: 1; /* Firefox */
    }
  }
}
