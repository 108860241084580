.homepage {
  padding-bottom: 15px;
  position: relative;

  /* Select all .block children of .homepage, including the first one */
  & > .block {
    margin-top: 0;

    @media(min-width: 768px) {
      margin-top: 80px;
    }
  }

  /* Select all but the first .block child of .homepage */
  & > .block ~ .block {
    margin-top: 75px;
  }

  .back-to-top-container {
    bottom: 25px;
    margin-bottom: 10px;
    position: sticky;
    float: right;
    padding-right: 20px;
    z-index: 20;
  }

  .three-cols {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 15px;
    justify-content: center;
    row-gap: 70px;

    @media(min-width: 768px) {
      grid-template-columns: repeat(3, 230px);
      row-gap: inherit;
    }

    @media(min-width: 992px) {
      grid-template-columns: repeat(3, 303px);
      row-gap: inherit;
    }

    @media(min-width: 1200px) {
      grid-template-columns: repeat(3, 370px);
      row-gap: inherit;
    }
  }

  .two-cols {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 15px;
    row-gap: 70px;

    @media(min-width: 768px) {
      grid-template-columns: 50fr 50fr;
      row-gap: inherit;
    }
  }

  .artp-btn {
    font-size: 14px;

    @media(min-width: 768px) {
      padding: 15px 40px;
    }
  }

  h2.title {
    font-family: 'Open Sans', 'Sans-Serif', serif;
    font-weight: 400;
    color: #1d1d1f;
    font-size: 32px;
    line-height: 39px;
    position: relative;
    margin-bottom: 15px;

    .all {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      text-transform: uppercase;
      margin: 5px 0 30px;

      @media(min-width: 768px) {
        position: absolute;
        right: 15px;
        font-size: 14px;
        bottom: -10px;
        margin: inherit;
      }

      a {
        color: #1d1d1f;

        &.marketplace {
          color: #e53140;
        }
      }
    }
  }

  h3.title {
    margin-top: 10px;
    font-size: 26px;
  }

  .radius {
    border-radius: 10px;
    overflow: hidden;
  }
}
