.search-bar-drawer-marketplace {
  display: flex;
  flex-flow: row;
  width: 100%;

  @media (max-width: 768px) {
    flex-flow: column;
  }
  
  .artp-search-bar {
    flex: auto 1;
    width: auto;
    
    input {
      border-radius: 3px;
      font-size: 12px;
      height: 30px;
      line-height: 1.5;
      padding: 5px 10px;
    }
  }
}
