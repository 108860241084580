@use "sass:color";
@use '../../../../../assets/stylesheets/artp_colors';

.marketplace-stores-common-store-managment-menu {
  $color-active: artp_colors.$gray-light;

  background-color: artp_colors.$white;
  border-bottom: solid 1px color.adjust($color-active, $lightness: -3%);
  position: relative;
  z-index: 2;

  @media(max-width: 991px) {
    height: auto;
  }

  .items {
    display: flex;
    flex-flow: row;
    margin: 0;
    overflow-x: auto;
    padding: 0;
    transition: all linear 0.5s;
    width: 100%;
    z-index: 40;

    &.ng-hide {
      opacity: 0;
    }
    
    .notification {
      background-color: artp_colors.$alert-danger-bg;
      display: flex;
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100%;
      
      .icon {
        margin-left: 5px;
        margin-right: 5px;
        width: 15px;
      }
      
      a {
        color: inherit;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          background-color: color.adjust(artp_colors.$alert-danger-bg, $lightness: -5%);
        }
      }
    }

    .seller-menu {
      display: flex;
      flex-direction: column;
      margin: 0;

      .entries {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;

        @media(max-width: 991px) {
          align-items: center;
          height: 55px;
        }

        &.separators {
          border-left: solid 1px artp_colors.$gray;
          border-right: solid 1px artp_colors.$gray;
        }

        .item {
          align-items: center;
          flex: 1 1 auto;
          padding: 10px 7px;
          position: relative;
          text-align: center;
          white-space: nowrap;

          .badge {
            position: absolute;
            right: -8px;
            top: -4px;
            z-index: 1;
          }

          @media(max-width: 991px) {
            font-size: 12px;
            white-space: normal;

            .badge {
              display: none;
            }
          }

          &.icon {
            padding: 5px 5px 0;

            &.svg {
              padding-left: 0;
              padding-top: 0;
            }

            img {
              width: 40px;
            }
          }

          &.active {
            background-color: color.adjust($color-active, $lightness: -5%);
            border: solid 1px color.adjust($color-active, $lightness: -8%);

            &:hover {
              a {
                cursor: default;
                text-decoration: none;
              }
            }
          }

          &.disabled {
            color: #ccc;

            &:hover {
              cursor: not-allowed;
            }
          }

          a {
            color: artp_colors.$gray-darker;
          }
        }
      }

      .title {
        color: artp_colors.$gray-dark;
        font-size: 14px;
        font-weight: normal;
        padding: 10px 0;
        padding-bottom: 5px;
        padding-left: 20px;
        width: 100%;

        &.first {
          padding-left: 0;
        }

        a {
          font-size: 12px;
          margin-left: 10px;
          margin-top: -3px;
        }

        @media(max-width: 767px) {
          font-size: 14px;
          height: auto;
        }

        @media(max-width: 991px) {
          font-size: 16px;
        }
      }
    }
  }
}
