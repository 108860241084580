.subscriptions-purchase-promotion {
  background-color: #233A84;
  box-shadow: 0 0 10px #233A84;
  font: normal normal normal 16px/28px Open Sans;
  margin-bottom: 40px;
  margin-top: 10px;
  padding: 20px;
  font-weight: 300;
  color: #fff;
  border-radius: 10px;

  a {
    color: #fff;
    text-decoration: underline;
  }

  b {
    font-weight: 600;
  }
}
